<template>
    <div class="mainPanel">
        <h1>Export Panel</h1>
        <div class="filters">
            <div class="filter">
                <div class="datePicker">
                    <h3 for="">Select the date:</h3>
                    <Datepicker
                        ref="date"
                        range
                        v-model="selectedDate"
                        :enableTimePicker="false"
                        format="yyyy/MM/dd"
                        locale="it"
                        lang="it"
                    />
                </div>
                <div
                    v-if="
                        $store.state.isAdmin ===
                        '864de6b1-0edd-4fb2-b9f3-39cc382c0278'
                    "
                >
                    <h3 for="">Select customer:</h3>
                    <select
                        class="input"
                        ref="customer"
                        @change="changeCustomer"
                        v-model="customer_id"
                    >
                        <option value="">Choose Customer</option>
                        <option
                            :value="{ uuid: customer.uuid, id: customer.id }"
                            v-for="customer in customers"
                            :key="customer.id"
                        >
                            {{ customer.name }}
                        </option>
                    </select>
                </div>
                <div v-if="isCustomerDropdown">
                    <h3 for="">Select category:</h3>
                    <select
                        class="input"
                        ref="customer"
                        @change="changeConfiguration"
                        v-model="configuration"
                    >
                        <option value="">Choose category</option>
                        <option
                            :value="{
                                uuid: configuration.uuid_landing,
                                id: configuration.id,
                            }"
                            v-for="configuration in configurations"
                            :key="configuration.id"
                        >
                            {{ configuration.alias }}
                        </option>
                    </select>
                </div>
                <div v-if="isAdminCategoryDropdown">
                    <h3 for="">Select category:</h3>
                    <select
                        class="input"
                        ref="customer"
                        @change="changeAdminConfiguration"
                        v-model="adminConfiguration"
                    >
                        <option value="">Choose category</option>
                        <option
                            :value="{
                                uuid: configuration.uuid_landing,
                                id: configuration.id,
                            }"
                            v-for="configuration in adminConfigurations"
                            :key="configuration.id"
                        >
                            {{ configuration.name }}
                        </option>
                    </select>
                </div>
            </div>
            <div class="button">
                <p class="error">{{ errorValue }}</p>
                <button @click="applyFilters">Apply Filter</button>
            </div>
        </div>
        <loader v-if="isLoader" />
        <div class="d-flex">
            <div class="removeColumn" v-if="isTable" ref="componentRef">
                <div class="arrow">
                    Toggle Columns
                    <img
                        @click="toggleColumnSection"
                        :class="isToggleColumn ? 'active-img' : ''"
                        src="../assets/up-arrow.svg"
                        alt=""
                    />
                </div>

                <div class="inside-toggle-column" v-if="isToggleColumn">
                    <div v-for="(column, key) in columns" :key="key">
                        <input
                            type="checkbox"
                            :id="key"
                            v-model="columns[key]"
                            @change="columnVisibility(key)"
                        />
                        <label :for="key">{{
                            capitalizeFirstLetter(
                                convertKeyToShowOnToggleColumn(key).replaceAll(
                                    "_",
                                    " "
                                )
                            )
                        }}</label>
                    </div>
                </div>
            </div>
            <!-- <div class="freezeColumn" v-if="isTable" ref="freezeColumn">
                <select name="" id="" v-model="froozenColumnValue">
                    <option value="">Freeze column</option>
                    <option :value="key" v-for="(column, key) in columnToFreeze" :key="key">{{ capitalizeFirstLetter(convertKeyToShowOnToggleColumn(key).replaceAll('_', ' ')) }}</option>
                </select>
            </div> -->
        </div>

        <div ref="table"></div>

        <div v-if="isTable" class="filters">
            <div class="button" style="margin: 10px 0; text-align: center">
                <button v-on:click="downloadTabulator()">
                    Download CSV export file
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import { ref } from "vue";
import moment from "moment-timezone";
moment.tz.guess();
import serverCall from "../config/Axios";
import Loader from "./Loader.vue";
import { TabulatorFull as Tabulator } from "tabulator-tables"; //import Tabulator library
import "tabulator-tables/dist/css/tabulator_semanticui.min.css";

export default {
    name: "App",
    components: {
        Loader,
    },

    data() {
        return {
            tabulator: null, //variable to hold your table
            tableData: [], //data for table to display
            tabulatorColumns: [],
            selectedDate: [new Date(), new Date()],
            paginationPageSize: null,
            search: "",
            columnDefs: [],
            rowData: [],
            isTable: false,
            isLoader: false,
            customer_id: "",
            landings: [],
            uuid_landing: "",
            customers: [],
            colResizeDefault: null,
            columns: [],
            headers: [],
            isToggleColumn: false,
            columnToFreeze: [],
            froozenColumnValue: "",
            isToggleColumnFroozen: false,
            fieldsPapaParse: [],
            errorValue: "",
            sortNum: 1,
            isCustomerDropdown: false,
            isAdminCategoryDropdown: false,
            configurations: [],
            configuration: "",
            adminConfiguration: "",
            adminConfigurations: [],
        };
    },
    created() {
        this.paginationPageSize = 10;
        this.colResizeDefault = "shift";
    },
    watch: {
        froozenColumnValue: function (newVal, oldVal) {
            if (this.newVal !== "") {
                // this.tabulator.updateColumnDefinition(oldVal, { frozen: false });
                // this.tabulator.updateColumnDefinition(newVal, { frozen: true });
            }
        },
        columns() {},
    },
    methods: {
        onClickOutside(event) {
            const collabsibles = this.$refs["componentRef"];
            if (!collabsibles || collabsibles.contains(event.target)) return;
            this.isToggleColumn = false;
        },
        convertKeyToShowOnToggleColumn(key) {
            if (key === "created_at") {
                // element = 'subscription_date'
                key = "subscription_date";
            }
            if (key.includes("utm")) {
                // element = 'subscription_date'
                key = key.replace("utm", "");
            }
            return key;
        },
        changeCustomer() {
            this.isAdminCategoryDropdown = false;
            if (this.tabulator) {
                this.tabulator.setData([]);
                this.tabulator.setColumns([]);
            }
            this.getLandingByIdCustomer(this.customer_id.id);
        },
        changeConfiguration() {
            if (this.tabulator) {
                this.tabulator.setData([]);
                this.tabulator.setColumns([]);
            }
            sessionStorage.setItem("$uuid_landing", this.configuration.uuid);
        },
        changeAdminConfiguration() {
            if (this.tabulator) {
                this.tabulator.setData([]);
                this.tabulator.setColumns([]);
            }
            sessionStorage.setItem(
                "$uuid_landing",
                this.adminConfiguration.uuid
            );
        },
        async getCustomers() {
            const customers = await serverCall("/customers", {}, "post", "");
            if (customers.data.message === "Token Expired") {
                console.log("token expired");
                sessionStorage.setItem("panelTokenExpired", true);
                this.logout();
            } else if (customers.data) {
                // this.customers = customers.data.filter(el =>
                //     el = el.name !== 'GiftCard'
                // )
                this.customers = customers.data;
            }
        },
        async getLandingByIdCustomer() {
            const landings = await serverCall(
                "/landings/getLandingByIdCustomer",
                {
                    id_customer: this.customer_id.id,
                },
                "post",
                ""
            );
            if (landings.data.message === "Token Expired") {
                console.log("token expired");
                sessionStorage.setItem("panelTokenExpired", true);
                this.logout();
            } else if (landings.data) {
                // this.customers = customers.data.filter(el =>
                //     el = el.name !== 'GiftCard'
                // )
                if (landings.data.length > 1) {
                    this.adminConfigurations = landings.data;
                    this.isAdminCategoryDropdown = true;
                } else {
                    this.landings = landings.data[0];
                    this.uuid_landing = landings.data[0].uuid_landing;
                    sessionStorage.setItem(
                        "$uuid_landing",
                        landings.data[0].uuid_landing
                    );
                }
            }
        },
        async getConfigurationByCustomerId() {
            const landings = await serverCall(
                "/landings/getConfigurationByCustomerId",
                {},
                "post",
                ""
            );
            if (landings.data) {
                this.configurations = landings.data;
                if (landings.data.length > 1) {
                    this.isCustomerDropdown = true;
                } else {
                    sessionStorage.setItem(
                        "$uuid_landing",
                        landings.data[0].uuid_landing
                    );
                }
            }
        },
        capitalizeFirstLetter(string) {
            return string.charAt(0).toUpperCase() + string.slice(1);
        },
        toggleColumnSection() {
            this.isToggleColumn = !this.isToggleColumn;
        },
        toggleColumnSectionFroozeen() {
            this.isToggleColumnFroozen = !this.isToggleColumnFroozen;
        },
        flattenObj(ob) {
            // The object which contains the
            // final result
            let result = {};
            // loop through the object "ob"
            for (const i in ob) {
                // We check the type of the i using
                // typeof() function and recursively
                // call the function again
                if (typeof ob[i] === "object" && !Array.isArray(ob[i])) {
                    const temp = this.flattenObj(ob[i]);
                    for (const j in temp) {
                        // Store temp in result
                        result[j] = temp[j];
                    }
                }
                // Else store ob[i] in result directly
                else {
                    result[i] = ob[i];
                }
            }
            return result;
        },
        async getLeads() {
            try {
                this.rowData = [];
                this.columnDefs = [];
                this.tabulatorColumns = [];
                this.headers = [];
                this.columns = [];
                this.isLoader = true;
                let body = {};
                if (
                    this.$store.state.isAdmin ===
                    "864de6b1-0edd-4fb2-b9f3-39cc382c0278"
                ) {
                    body = {
                        startDate: moment(this.selectedDate[0]).format(
                            "YYYY/MM/DD"
                        ),
                        endDate:
                            this.selectedDate[1] === null
                                ? moment(this.selectedDate[0]).format(
                                      "YYYY/MM/DD"
                                  )
                                : moment(this.selectedDate[1]).format(
                                      "YYYY/MM/DD"
                                  ),
                        uuid: this.customer_id.uuid,
                    };
                } else {
                    body = {
                        startDate: moment(this.selectedDate[0]).format(
                            "YYYY/MM/DD"
                        ),
                        endDate:
                            this.selectedDate[1] === null
                                ? moment(this.selectedDate[0]).format(
                                      "YYYY/MM/DD"
                                  )
                                : moment(this.selectedDate[1]).format(
                                      "YYYY/MM/DD"
                                  ),
                    };
                }

                this.isTable = true;
                let columnsArr = [];
                let fieldsForPapaParse = [];

                let self = this;

                this.tabulator = new Tabulator(this.$refs.table, {
                    pagination: true,
                    paginationMode: "remote",
                    filterMode: "remote",
                    sortMode: "remote",
                    headerVisible: true,
                    paginationCounter: "rows",
                    paginationSize: 10, //optional parameter to request a certain number of rows per page
                    ajaxURL: "https://trk.everset.dev/leads/getLeads",
                    ajaxConfig: {
                        method: "GET", //set request type to Position
                        headers: {
                            "Content-type": "application/json; charset=utf-8", //set specific content type
                            authorization:
                                "Bearer " + localStorage.getItem("panelToken"),
                        },
                    },
                    ajaxURLGenerator: function (url, config, params) {
                        const uuid = body.uuid;
                        let uuid_landing =
                            sessionStorage.getItem("$uuid_landing");
                        const { page, size } = params;
                        sessionStorage.setItem(
                            "$setUrl",
                            `?page=${page}&results=${size}&uuid=${uuid}&uuid_landing=${uuid_landing}&startDate=${body.startDate}&endDate=${body.endDate}&filter=` +
                                encodeURI(JSON.stringify(params.filter)) +
                                "&sort=" +
                                encodeURI(JSON.stringify(params.sort)) +
                                "&download=1"
                        );
                        return (
                            url +
                            `?page=${page}&results=${size}&uuid=${uuid}&uuid_landing=${uuid_landing}&startDate=${body.startDate}&endDate=${body.endDate}&filter=` +
                            encodeURI(JSON.stringify(params.filter)) +
                            "&sort=" +
                            encodeURI(JSON.stringify(params.sort)) +
                            "&download=0"
                        );
                    },
                    ajaxResponse: function (url, params, response) {
                        if (response.message === "Token Expired") {
                            sessionStorage.setItem("panelTokenExpired", true);
                            self.logout();
                        } else if (response.leads) {
                            self.isLoader = false;
                            sessionStorage.setItem("panelTokenExpired", false);

                            // Must configure with server side
                            let last_page = response.totalPages;
                            let last_row = response.totalLeads;
                            response.leads.forEach((el) => {
                                el.created_at = moment(el.created_at).format(
                                    "YYYY/MM/DD HH:mm:ss"
                                );
                                if (el.subscription_date) {
                                    el.subscription_date = moment(
                                        el.subscription_date
                                    ).format("YYYY/MM/DD HH:mm:ss");
                                    el.created_at = el.subscription_date;
                                    delete el.created_at;
                                }
                                if (
                                    !self.$store.state.isAdmin ===
                                    "864de6b1-0edd-4fb2-b9f3-39cc382c0278"
                                ) {
                                    if (el.utm_source) {
                                        if (
                                            el.utm_source.includes("tiktok") ||
                                            el.utm_source.includes(
                                                "facebook"
                                            ) ||
                                            el.utm_source.includes("meta") ||
                                            el.utm_source.includes("leadads")
                                        ) {
                                            el.utm_source = "Media buying";
                                        }
                                    }
                                }
                            });
                            // self.tabulator("setColumns", self.tabulatorColumns)
                            return {
                                data: response.leads,
                                last_page,
                                last_row,
                            };
                        }
                    },
                    autoColumns: true,
                    autoColumnsDefinitions: function (definitions) {
                        //definitions - array of column definition objects
                        definitions.forEach((col) => {
                            if (col.title === "created_at") {
                                // element = 'subscription_date'
                                col.title = "subscription_date";
                            }
                            if (col.title.includes("utm")) {
                                // element = 'subscription_date'
                                col.title = col.title.replace("utm", "");
                            }
                            col.title = self.capitalizeFirstLetter(
                                col.title.replaceAll("_", " ")
                            );
                            col["headerFilter"] = "input";
                            fieldsForPapaParse.push(
                                self.capitalizeFirstLetter(
                                    col.title.replaceAll("_", " ")
                                )
                            );
                            self.headers.push(col.title);
                            columnsArr.push({ [col.field]: true });
                            self.fieldsPapaParse = fieldsForPapaParse;
                        });
                        if (
                            localStorage.getItem(
                                "columnFilterTabulator_" + self.customer_id
                            )
                        ) {
                            self.columns = JSON.parse(
                                localStorage.getItem(
                                    "columnFilterTabulator_" + self.customer_id
                                )
                            );
                        } else {
                            self.columns = self.flattenObj(columnsArr);
                        }
                        return definitions;
                    },
                    // // paginationSizeSelector: [10, 100, 500, 1000],
                    // movableColumns: true,
                    // // paginationCounter: "rows",
                    // data: this.rowData, //link data to table
                    // columns: this.tabulatorColumns,//define table columns
                });

                setTimeout(() => {
                    this.checkVisibilityColumns();
                }, 100);
            } catch (e) {
                console.log(e);
            }
        },
        applyFilters() {
            if (
                this.$store.state.isAdmin ===
                "864de6b1-0edd-4fb2-b9f3-39cc382c0278"
            ) {
                if (!this.selectedDate) {
                    this.errorValue = "Select a valid date!";
                } else if (
                    this.customer_id.uuid === "" ||
                    !this.customer_id.uuid
                ) {
                    this.errorValue = "Select a customer!";
                } else if (
                    this.isAdminCategoryDropdown &&
                    (this.adminConfiguration.uuid === "" ||
                        !this.adminConfiguration.uuid)
                ) {
                    this.isTable = false;
                    this.errorValue = "Select a configuration!";
                } else {
                    this.errorValue = "";
                    this.getLeads();
                }
            } else {
                if (!this.selectedDate) {
                    this.isTable = false;
                    this.errorValue = "Select a valid date!";
                } else if (
                    this.isCustomerDropdown &&
                    (this.configuration.uuid === "" || !this.configuration.uuid)
                ) {
                    this.isTable = false;
                    this.errorValue = "Select a configuration!";
                } else {
                    this.errorValue = "";
                    this.getLeads();
                }
            }
        },

        columnVisibility(name) {
            localStorage.setItem(
                "columnFilterTabulator_" + this.customer_id.uuid,
                JSON.stringify(this.columns)
            );
            for (const property in this.columns) {
                if (this.columns[property] === false) {
                    this.tabulator.hideColumn(property);
                } else {
                    this.tabulator.showColumn(property);
                }
            }
        },
        checkVisibilityColumns() {
            for (const property in this.columns) {
                console.log(this.columns[property]);
                // console.log(this.tabulator.updateColumnDefinition(this.columns[property], {
                //         title: 'Test'
                //     }));
                if (this.columns[property] === false) {
                    this.tabulator.hideColumn(property);
                } else {
                    this.tabulator.showColumn(property);
                }
            }
        },
        logout() {
            localStorage.setItem("isAuthenticated", "null");
            localStorage.removeItem("panelToken");
            this.$router.push({ name: "Login" });
        },
        setColumnDefs(columns) {
            debugger;
            columns.forEach((element) => {
                this.columnDefs.push({ headerName: element, field: element });
            });
        },
        async downloadTabulator() {
            let cvsWheader = [];
            var today = new Date();
            var dd = String(today.getDate()).padStart(2, "0");
            var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
            var yyyy = today.getFullYear();
            today = dd + "/" + mm + "/" + yyyy;
            let data = await serverCall(
                "/leads/getLeads" + sessionStorage.getItem("$setUrl"),
                {},
                "get",
                ""
            );
            // const changeSubsDateKey = data.data.leads.map(({
            //     created_at: subscription_date,
            //     ...rest
            // }) => ({
            //     subscription_date,
            //     ...rest
            // }));
            data.data.leads.forEach((el) => {
                el.subscription_date = el.created_at;
                if (el.subscription_date) {
                    el.subscription_date = moment(el.subscription_date).format(
                        "YYYY/MM/DD HH:mm:ss"
                    );
                }
                delete el.created_at;
            });
            let result = data.data.leads.map((o) =>
                Object.fromEntries(
                    Object.entries(o).map(([k, v]) => [
                        this.capitalizeFirstLetter(k.replace(/\_/, " ")),
                        v,
                    ])
                )
            );
            // console.log(result);
            var csv = this.$papa.unparse(result);
            var results = this.$papa.parse(csv);
            results.data[0] = this.fieldsPapaParse;
            // console.log(results);
            var csvFinal = this.$papa.unparse(results.data);
            // console.log(csvFinal);
            this.$papa.download(csvFinal, today);
            // this.tabulator.downloadToTab("csv", today + ".csv")
        },
    },
    mounted() {
        this.$store.commit("setAdmin", localStorage.getItem("isAdminPanel"));
        if (
            this.$store.state.isAdmin === "864de6b1-0edd-4fb2-b9f3-39cc382c0278"
        ) {
            this.getCustomers();
        } else {
            this.getConfigurationByCustomerId();
        }
        document.addEventListener("click", this.onClickOutside);
    },
    beforeDestroy() {
        document.removeEventListener("click", this.onClickOutside);
    },
    computed: {},
    setup() {
        const gridApi = ref(null); // Optional - for accessing Grid's API
        const componentRef = ref();

        // Obtain API from grid's onGridReady event
        const onGridReady = (params) => {
            gridApi.value = params.api;
        };

        const onPageSizeChanged = () => {
            var value = document.getElementById("page-size").value;
            gridApi.value.paginationSetPageSize(Number(value));
        };

        // DefaultColDef sets props common to all Columns
        const defaultColDef = {
            sortable: true,
            filter: true,
            flex: 1,
            resizable: true,
        };

        const onBtnExport = () => {
            // ag grid download
            var today = new Date();
            var dd = String(today.getDate()).padStart(2, "0");
            var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
            var yyyy = today.getFullYear();
            today = dd + "/" + mm + "/" + yyyy;
            gridApi.value.exportDataAsCsv({ fileName: today });
        };
        // Example load data from sever

        return {
            onGridReady,
            onPageSizeChanged,
            onBtnExport,
            defaultColDef,
            cellWasClicked: (event) => {
                // Example of consuming Grid Event
                console.log("cell was clicked", event);
            },
        };
    },
};
</script>

<style lang="less"></style>
